import React from 'react';
import Enonicarousel, { type EnonicarouselProps } from '../../../react4xp/components/Enonicarousel';


interface PromotionBannerProps {
	carousel: EnonicarouselProps
}


export default function PromotionBanner(props: PromotionBannerProps) {
	const { carousel } = props;

	return (
		<div className="promotion-banner">
			<Enonicarousel {...carousel} />
		</div>
	);
}
